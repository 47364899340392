<template>
  <v-form
    ref="form"
    class="rcc-detailing-form"
    v-model="valid"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-detailing-form__form-body">
      <rcc-text-input
        label="Детализация"
        width="40%"
        v-model="form.name"
        :rules="[value => isRequiredField({ value, field: 'Детализация' })]"
      />
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'detailing' })"/>
  </v-form>
</template>

<script>
import DetailingApi from '@/api/detailing'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccTextInput from 'Components/controls/rcc-text-input'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'

export default {
  components: {
    RccTextInput,
    RccFooterButtons
  },

  mixins: [Page, Form, DetailingApi],

  data() {
    return {
      form: {
        name: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-detailing-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__booking-section {
    display: flex;
    gap: 20px;
  }

  &__number-input {
    flex: none;
    width: 250px;
  }

  &__form-section {
    margin: 10px 0;
  }
}
</style>
